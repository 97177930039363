import { tns } from 'tiny-slider/src/tiny-slider';
import '../../node_modules/tiny-slider/src/tiny-slider.scss';

const interactSlider = (slider) => {
  const stopAutoplay = () => {
    slider.pause();
  };

  const startAutoplay = () => {
    slider.play();
  };

  slider.events.on('dragStart', stopAutoplay);
  slider.events.on('touchstart', stopAutoplay);
  slider.events.on('dragEnd', startAutoplay);
  slider.events.on('touchEnd', startAutoplay);
};

const bindArrows = (left, right, slider, tabItem) => {
  let leftArrows = null;
  let rightArrows = null;
  if (!tabItem) {
    leftArrows = document.querySelectorAll(left);
    rightArrows = document.querySelectorAll(right);
  } else {
    leftArrows = tabItem.querySelectorAll(left);
    rightArrows = tabItem.querySelectorAll(right);
  }

  leftArrows.forEach((leftArrow) => {
    leftArrow.addEventListener('click', () => {
      slider.pause();
      slider.goTo('prev');
      slider.play();
    });
  });

  rightArrows.forEach((rightArrow) => {
    rightArrow.addEventListener('click', () => {
      slider.pause();
      slider.goTo('next');
      slider.play();
    });
  });

  interactSlider(slider);
};

if (document.querySelector('[data-article-slider]')) {
  const sliderArticle = tns({
    container: '[data-article-slider]',
    items: 3,
    slideBy: 1,
    navPosition: 'bottom',
    nav: true,
    speed: 600,
    autoplay: false,
    controls: false,
    loop: true,
    swipeAngle: false,
    mouseDrag: true,
    rewind: false,
    autoplayButtonOutput: false,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  });

  bindArrows('[data-article-left]', '[data-article-right]', sliderArticle);
}

if (document.querySelector('[data-why-slider]')) {
  const sliderWhy = tns({
    container: '[data-why-slider]',
    items: 1,
    slideBy: 1,
    nav: true,
    speed: 600,
    autoplay: false,
    controls: false,
    loop: true,
    swipeAngle: false,
    mouseDrag: true,
    rewind: false,
    autoplayButtonOutput: false,
  });

  bindArrows('[data-why-left]', '[data-why-right]', sliderWhy);
}

if (document.querySelector('[data-advantages-slider]')) {
  const sliderAdvantages = tns({
    container: '[data-advantages-slider]',
    items: 3,
    slideBy: 1,
    nav: true,
    speed: 700,
    autoplay: false,
    controls: false,
    loop: true,
    swipeAngle: false,
    rewind: false,
    autoplayButtonOutput: false,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1240: {
        items: 3,
      },
    },
  });

  bindArrows('[data-advantages-left]', '[data-advantages-right]', sliderAdvantages);
}

// TODO сделать инициализацию двух слайдеров одновременно в одном контейнере
// ищем по атрибуту контейнер и внутри инит слайдеров
// анонимную функцию которая принимает в себя инфо и при переключении первого слайдера передаем
// goTo(цифра слайда) передаем ее во второй

document.querySelectorAll('[data-offer-sliders]').forEach((item) => {
  const imagesSlider = tns({
    container: item.querySelector('[data-offer-images-slider'),
    items: 1,
    slideBy: 1,
    nav: true,
    speed: 700,
    autoplay: false,
    controls: false,
    loop: false,
    swipeAngle: false,
    rewind: false,
    autoplayButtonOutput: false,
  });

  const descriptionSlider = tns({
    container: item.querySelector('[data-offer-description-slider]'),
    items: 1,
    slideBy: 1,
    nav: false,
    speed: 700,
    autoplay: false,
    controls: false,
    loop: false,
    swipeAngle: false,
    rewind: false,
    autoplayButtonOutput: false,
  });

  bindArrows('[data-offer-left]', '[data-offer-right]', imagesSlider, item);

  const syncSliders = function (info) {
    if (info.container.hasAttribute('data-offer-images-slider') && !info.container.hasAttribute('data-blocked')) {
      info.container.setAttribute('data-blocked', '');
      descriptionSlider.goTo(info.index);
      info.container.removeAttribute('data-blocked');
    } else if (info.container.hasAttribute('data-offer-description-slider') && !info.container.hasAttribute('data-blocked')) {
      info.container.setAttribute('data-blocked', '');
      imagesSlider.goTo(info.index);
      info.container.removeAttribute('data-blocked');
    }
  };

  imagesSlider.events.on('transitionStart', syncSliders);
  descriptionSlider.events.on('transitionStart', syncSliders);
});