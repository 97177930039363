import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";
import Accordion from '../../components/accordion/accordion';

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  let modal = new Modal(this, {
    closerText: '',
    onFormSuccess: function () {
      if (link.dataset.goal) {
        let action = 'click';
        if(link.dataset.goalAction) {
          action = link.dataset.goalAction;
        }
        window.goal(link.dataset.goal, action);

        if (link.dataset.goalFacebook && link.dataset.goalAction) {
          window.fbq('track', link.dataset.goalFacebook);
        }
      }
    },
  });
  return false;
});

new Accordion();