import axios from 'axios';

const mainFunction = () => {
  async function getRequestIntegrationsGrid() {
    const requestData = {
      method: 'get',
      url: '/get_integrations_grid',
    };

    return axios(requestData);
  }
  // здарова е*ать, закрывать глаза, открывай рот
  const selectHandler = (e, integrationsGrid) => {
    const savingOne = document.querySelector('[data-integration-saving-one]');
    const savingTwo = document.querySelector('[data-integration-saving-two]');
    const savingThree = document.querySelector('[data-integration-saving-three]');
    const thirdMonth = document.querySelector('[data-month-third]');
    const sixthMonth = document.querySelector('[data-month-sixth]');
    const twelfthMonth = document.querySelector('[data-month-twelfth]');
    const twentyFourthMonth = document.querySelector('[data-month-twenty-fourth]');
    const selectValue = document.querySelector('[data-integration-select]').value;

    if (selectValue === '1-5 человек') {
      savingOne.innerText = integrationsGrid.sumsGrid[0].monthSum[0].saving + ' руб';
      savingTwo.innerText = integrationsGrid.sumsGrid[0].monthSum[1].saving + ' руб';
      savingThree.innerText = integrationsGrid.sumsGrid[0].monthSum[2].saving + ' руб';
      thirdMonth.innerText = integrationsGrid.sumsGrid[0].monthSum[0].sum + ' руб';
      sixthMonth.innerText = integrationsGrid.sumsGrid[0].monthSum[1].sum + ' руб';
      twelfthMonth.innerText = integrationsGrid.sumsGrid[0].monthSum[2].sum + ' руб';
      twentyFourthMonth.innerText = integrationsGrid.sumsGrid[0].monthSum[3].sum + ' руб';
    } else if (selectValue === '6-15 человек') {
      savingOne.innerText = integrationsGrid.sumsGrid[1].monthSum[0].saving + ' руб';
      savingTwo.innerText = integrationsGrid.sumsGrid[1].monthSum[1].saving + ' руб';
      savingThree.innerText = integrationsGrid.sumsGrid[1].monthSum[2].saving + ' руб';
      thirdMonth.innerText = integrationsGrid.sumsGrid[1].monthSum[0].sum + ' руб';
      sixthMonth.innerText = integrationsGrid.sumsGrid[1].monthSum[1].sum + ' руб';
      twelfthMonth.innerText = integrationsGrid.sumsGrid[1].monthSum[2].sum + ' руб';
      twentyFourthMonth.innerText = integrationsGrid.sumsGrid[1].monthSum[3].sum + ' руб';
    } else if (selectValue === '16-30 человек') {
      savingOne.innerText = integrationsGrid.sumsGrid[2].monthSum[0].saving + ' руб';
      savingTwo.innerText = integrationsGrid.sumsGrid[2].monthSum[1].saving + ' руб';
      savingThree.innerText = integrationsGrid.sumsGrid[2].monthSum[2].saving + ' руб';
      thirdMonth.innerText = integrationsGrid.sumsGrid[2].monthSum[0].sum + ' руб';
      sixthMonth.innerText = integrationsGrid.sumsGrid[2].monthSum[1].sum + ' руб';
      twelfthMonth.innerText = integrationsGrid.sumsGrid[2].monthSum[2].sum + ' руб';
      twentyFourthMonth.innerText = integrationsGrid.sumsGrid[2].monthSum[3].sum + ' руб';
    } else if (selectValue === '31-60 человек') {
      savingOne.innerText = integrationsGrid.sumsGrid[3].monthSum[0].saving + ' руб';
      savingTwo.innerText = integrationsGrid.sumsGrid[3].monthSum[1].saving + ' руб';
      savingThree.innerText = integrationsGrid.sumsGrid[3].monthSum[2].saving + ' руб';
      thirdMonth.innerText = integrationsGrid.sumsGrid[3].monthSum[0].sum + ' руб';
      sixthMonth.innerText = integrationsGrid.sumsGrid[3].monthSum[1].sum + ' руб';
      twelfthMonth.innerText = integrationsGrid.sumsGrid[3].monthSum[2].sum + ' руб';
      twentyFourthMonth.innerText = integrationsGrid.sumsGrid[3].monthSum[3].sum + ' руб';
    } else if (selectValue === '61-100 человек') {
      savingOne.innerText = integrationsGrid.sumsGrid[4].monthSum[0].saving + ' руб';
      savingTwo.innerText = integrationsGrid.sumsGrid[4].monthSum[1].saving + ' руб';
      savingThree.innerText = integrationsGrid.sumsGrid[4].monthSum[2].saving + ' руб';
      thirdMonth.innerText = integrationsGrid.sumsGrid[4].monthSum[0].sum + ' руб';
      sixthMonth.innerText = integrationsGrid.sumsGrid[4].monthSum[1].sum + ' руб';
      twelfthMonth.innerText = integrationsGrid.sumsGrid[4].monthSum[2].sum + ' руб';
      twentyFourthMonth.innerText = integrationsGrid.sumsGrid[4].monthSum[3].sum + ' руб';
    } else if (selectValue === 'от 101 человек') {
      savingOne.innerText = integrationsGrid.sumsGrid[5].monthSum[0].saving + ' руб';
      savingTwo.innerText = integrationsGrid.sumsGrid[5].monthSum[1].saving + ' руб';
      savingThree.innerText = integrationsGrid.sumsGrid[5].monthSum[2].saving + ' руб';
      thirdMonth.innerText = integrationsGrid.sumsGrid[5].monthSum[0].sum + ' руб';
      sixthMonth.innerText = integrationsGrid.sumsGrid[5].monthSum[1].sum + ' руб';
      twelfthMonth.innerText = integrationsGrid.sumsGrid[5].monthSum[2].sum + ' руб';
      twentyFourthMonth.innerText = integrationsGrid.sumsGrid[5].monthSum[3].sum + ' руб';
    }

    // installLinks.forEach((installLink) => {
    //   const tariffBlock = installLink.closest('[data-integration-tariff]');
    //   const tariffTermValue = tariffBlock.querySelector('[data-integration-term]').innerHTML;
    //   const tariffSum = tariffBlock.querySelector('[data-integration-sum]').innerHTML;
    //   const tariffUsersCount = document.querySelector('[data-integration-select]').value;
    //   let tariffDiscount = '';
    //   let totalParams = '';
    //   const integrationLink = tariffBlock.querySelector('[data-integration-link]');
    //
    //   if (tariffBlock.querySelector('[data-integration-discount]')) {
    //     tariffDiscount = tariffBlock.querySelector('[data-integration-discount]').innerHTML;
    //   }
    //
    //   if (tariffDiscount) {
    //     totalParams = `${tariffUsersCount} пользователей ${tariffTermValue} ${tariffSum} ${tariffDiscount}`;
    //   } else {
    //     totalParams = `${tariffUsersCount} пользователей ${tariffTermValue} ${tariffSum}`;
    //   }
    //
    //   integrationLink.href = `/request/recall_modal?total_params=${totalParams}`;
    // });
  };

  getRequestIntegrationsGrid().then((result) => {
    const integrationsGrid = result.data;
    const select = document.querySelector('[data-integration-select]');
    select.addEventListener('change', (e) => {
      selectHandler(e, integrationsGrid);
    });
  });
};

document.addEventListener('DOMContentLoaded', mainFunction);